import {
  Box,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text
} from '@homebotapp/hb-react-component-catalog'
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Divider } from '../../shared/Navbar'
import { useRecoilState } from 'recoil'
import { recentlySelectedLocations } from './state/recentlySelectedLocations'
import { useTrackingContext } from '../../../providers/tracking'
import { CurrentSelectedLocation } from './state/currentSelectedLocation'

export interface GoogleAutoCompleteProps {
  showFullModal?: boolean
  setPlaceDetails?: (details: any) => void
  onPlaceSelected: (place: any) => void
  onClose?: () => void
  types?: string[] // for available types, see: https://developers.google.com/maps/documentation/javascript/place-autocomplete#constrain-place-types
}

export const MSG = defineMessages({
  findAPlace: {
    id: 'General.findAPlace',
    defaultMessage: 'Find a place...'
  },
  cancel: {
    id: 'General.cancel',
    defaultMessage: 'Cancel'
  },
  recent: {
    id: 'General.recent',
    defaultMessage: 'Recent'
  },
  places: {
    id: 'General.places',
    defaultMessage: 'Places'
  },
  loading: {
    id: 'General.loading',
    defaultMessage: 'Loading...'
  },
  poweredByGoogle: {
    id: 'General.poweredByGoogle',
    defaultMessage: 'Powered by Google'
  }
})

const AddressOption = ({ address, onClick }) => {
  return (
    <Box
      mb={1}
      _hover={{
        background: 'neutral.200'
      }}
    >
      <Box onClick={onClick} m={0} cursor='pointer'>
        <Text noOfLines={1} m={0} size='sm' fontWeight={500}>
          {address}
        </Text>
      </Box>
      <Divider style={{ height: 2, margin: '0' }} />
    </Box>
  )
}

export const GoogleAutoComplete = ({
  showFullModal = true,
  onPlaceSelected,
  setPlaceDetails,
  onClose,
  types = ['locality', 'administrative_area_level_3', 'postal_code']
}: GoogleAutoCompleteProps) => {
  const intl = useIntl()
  const [searchTerm, setSearchTerm] = useState('')
  const { trackingClient } = useTrackingContext()

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    debounce: 500
  })

  const [recentLocations, setRecentLocations] =
    useRecoilState<google.maps.places.AutocompletePrediction[]>(recentlySelectedLocations)

  const onClick = (place: CurrentSelectedLocation) => {
    trackingClient?.autocompleteInteraction({
      ui_context: 'Google_Auto_Complete',
      descriptor: place.description,
      action: 'Selected',
      guid: '3GtcZMbe9X4C7YUKKiRUEE'
    })
    // check if the place is already in the recent locations
    if (recentLocations?.findIndex(r => r.description === place.description) === -1) {
      setRecentLocations([...(recentLocations || []), place].splice(-3))
    }

    onPlaceSelected(place)

    if (setPlaceDetails) {
      placesService?.getDetails(
        {
          placeId: place.place_id
        },
        placeDetails => setPlaceDetails(placeDetails)
      )
    }
  }

  if (showFullModal) {
    return (
      <Modal closeOnOverlayClick isOpen onClose={() => onClose?.()}>
        <ModalOverlay />
        <ModalContent mx={4} borderRadius={16}>
          <Flex px={5} py={5} alignItems='center' flexDirection='row'>
            <Flex flex={1}>
              <Input
                size='sm'
                tracking={{
                  guid: '3GtcZMbe9X4C7YUKKiAyjg',
                  descriptor: 'Search for a place'
                }}
                background='neutral.200'
                flex={1}
                mr={5}
                name={MSG.findAPlace.id}
                value={searchTerm}
                onChange={e => {
                  getPlacePredictions({
                    input: e.target.value,
                    componentRestrictions: {
                      country: 'us'
                    },
                    types
                  })
                  setSearchTerm(e.target.value)
                }}
                placeholder={intl.formatMessage(MSG.findAPlace)}
              />
            </Flex>

            <Text m={0} onClick={onClose} size='sm' cursor='pointer' textColor='primary.500'>
              {intl.formatMessage(MSG.cancel)}
            </Text>
          </Flex>

          <ModalBody>
            {recentLocations && (
              <Box>
                <Flex mb={3} alignItems='center'>
                  <Icon mr={2} name='clock' color='neutral.500' />
                  <Text m={0} size='sm' fontWeight={600}>
                    {intl.formatMessage(MSG.recent)}
                  </Text>
                </Flex>
                {recentLocations?.map((recent, index) => (
                  <AddressOption
                    key={index}
                    address={recent.description}
                    onClick={() => {
                      onClick(recent as CurrentSelectedLocation)
                    }}
                  />
                ))}
              </Box>
            )}

            {placePredictions?.length > 0 && (
              <Box mt={5}>
                <Text my={2} size='sm' fontWeight={600}>
                  {intl.formatMessage(MSG.places)}
                </Text>
                {isPlacePredictionsLoading && <Text>{intl.formatMessage(MSG.loading)}</Text>}
                {placePredictions.map((prediction, index) => (
                  <AddressOption
                    key={index}
                    address={prediction.description}
                    onClick={() => {
                      onClick(prediction as CurrentSelectedLocation)
                    }}
                  />
                ))}
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent='flex-end'>
              <Text size='sm' m={0} textColor='white'>
                {intl.formatMessage(MSG.poweredByGoogle)}
              </Text>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  } else {
    return (
      <>
        <Flex px={5} py={5} alignItems='center' flexDirection='row'>
          <Flex flex={1}>
            <Input
              size='sm'
              tracking={{
                guid: '3GtcZMbe9X4C7YUKKiAyjg',
                descriptor: 'Search for a place'
              }}
              background='neutral.200'
              flex={1}
              mr={5}
              name={MSG.findAPlace.id}
              value={searchTerm}
              onChange={e => {
                getPlacePredictions({
                  input: e.target.value,
                  componentRestrictions: {
                    country: 'us'
                  },
                  types
                })
                setSearchTerm(e.target.value)
              }}
              placeholder={intl.formatMessage(MSG.findAPlace)}
            />
          </Flex>
        </Flex>
        {placePredictions?.length > 0 && (
          <>
            <Text my={2} size='sm' fontWeight={600}>
              {intl.formatMessage(MSG.places)}
            </Text>
            {isPlacePredictionsLoading && <Text>{intl.formatMessage(MSG.loading)}</Text>}
            {placePredictions.map((prediction, index) => (
              <AddressOption
                key={index}
                address={prediction.description}
                onClick={() => {
                  onClick(prediction as CurrentSelectedLocation)
                }}
              />
            ))}
          </>
        )}
      </>
    )
  }
}
