import { ListingStatusEnum } from '../types/listingStatus'

export const LISTING = 'listing'
export const LISTINGS_ZIP_CODE = 'listingsZipCode'
export const LISTINGS_SEARCH = 'listingsSearch'
export const LISTING_MARKERS_SEARCH = 'listingMarkersSearch'
export const LISTINGS_FAVORITES = 'listingsFavorites'
export const LISTINGS_SIMILAR_HOMES = 'similarHomes'
export const LISTINGS_PURCHASING_POWER = 'listingsPurchasingPower'
export const LISTINGS_EQUITY_SMART_SEARCH = 'listingsEquitySmartSearch'
export const LISTINGS_MAX_PRICE_MULTIPLIER = 1.2
export const LISTINGS_MIN_PRICE_MULTIPLIER = 0.8
export const LISTING_COUNTY = 'county'

export const PENDING = 'Pending'
export const ACTIVE = 'Active'
export const INACTIVE = 'Inactive'
export const COMING_SOON = 'Coming Soon'

export const listingsSearchCriteria = {
  EQUITY_SMART_SEARCH_SIMILAR_HOME_DISTANCE: 10,
  SEARCH_RESULTS_PAGE_LIMIT: 250,
  SIMILAR_HOME_DISTANCE: 3,
  SIMILAR_HOME_LIMIT: 20,
  SIMILAR_HOME_MAX_PRICE_MULTIPLIER: 1.2,
  SIMILAR_HOME_MIN_PRICE_MULTIPLIER: 0.8,
  PURCHASING_POWER_HOME_LIMIT: 20,
  PURCHASING_POWER_MAX_PRICE_MULTIPLIER: 1.2,
  PURCHASING_POWER_MIN_PRICE_MULTIPLIER: 0.8,
  EXTENDED_HOME_DISTANCE: 10,
  MAX_NUMBER_OF_LISTING_MARKERS_FETCHED: 500
}

export const HOMEBOT_LISTINGS_FEATURE = 'homebotListingsFeature'

// SEARCH
export const DEBOUNCE_TIME_LISTING_SEARCH = 300
export const LISTING_INPUT_ID = 'listingSearchInput'
export const LISTING_RESULTS_LIST_ID = 'listingSearchResultsList'
export const LISTING_DESCRIBE_ID = 'listingSearchDescribe'
export const LISTING_SEARCH_MAX_LENGTH = 80
export const LISTING_SEARCH_ARIA_LABELLED_BY = 'listingSearchLabel'
export const INITIAL_NUM_SAVED_SEARCHES_TO_DISPLAY = 3
export const INITIAL_NUM_SAVED_SEARCHES_TO_DISPLAY_WITH_RECENT_SEARCHES = 1
export const NUMBER_OF_SAVED_RECENT_SEARCHES = 2

// TYPES OF SEARCHES
export const RECENT_SEARCHES = 'recentSearches'
export const SAVED_SEARCHES = 'savedSearches'
export const PLACE_SEARCHES = 'placeSearches'
export const NO_OP_MORE_BUTTON = 'noOp'

// FILTERS
export const LOCATION = 'location'
export const LOCATION_ID = 'locationId'
export const POSTAL_CODE = 'postalCode'
export const STREET = 'street'
export const NEIGHBOURHOOD = 'neighbourhood'
export const COUNTY = 'county'
export const CITY = 'city'
export const STATE = 'state'
export const PLACE_ID = 'placeId'
export const LOCATION_LEVELS = [POSTAL_CODE, STREET, NEIGHBOURHOOD, COUNTY, CITY, STATE]
export const ORDER = 'order'
export const MAX_PRICE = 'maxPrice'
export const MIN_PRICE = 'minPrice'
export const DOWN_PAYMENT = 'downPayment'
export const MAX_MONTHLY_PAYMENT = 'maxMonthlyPayment'
export const MIN_MONTHLY_PAYMENT = 'minMonthlyPayment'
export const MIN_BATHROOMS_COUNT = 'minBathroomsCount'
export const MIN_BEDROOMS_COUNT = 'minBedroomsCount'
export const MIN_SQFT = 'minSqft'
export const MAX_SQFT = 'maxSqft'
export const MIN_ACRES = 'minAcres'
export const MAX_ACRES = 'maxAcres'
export const PROPERTY_TYPES = 'propertyTypes'
export const SQFT_SUFFIX = ' ft²'
export const ACRES_SUFFIX = ' ac'
export const MIN_YEAR_BUILT = 'minYearBuilt'
export const MAX_YEAR_BUILT = 'maxYearBuilt'
export const HOME_TYPES_ALL = 'All'
export const HOME_TYPES_LAND = 'Land'
export const HOME_TYPES_FARM = 'Farm'
export const HOME_TYPES_RESIDENTIAL = 'Residential'
export const STATUSES = 'statuses'
export const HOME_TYPES = [HOME_TYPES_RESIDENTIAL, HOME_TYPES_LAND, HOME_TYPES_FARM]
export const LISTING_STATUSES = [
  ListingStatusEnum.ACTIVE,
  ListingStatusEnum.PENDING,
  ListingStatusEnum.COMING_SOON,
  ListingStatusEnum.ACTIVE_UNDER_CONTRACT,
  ListingStatusEnum.CLOSED
]
export const HAS_SELLER_CONCESSION = 'hasSellerConcession'
export const IS_ASSUMABLE = 'isAssumable'

// Display
export const MAX_FULL_NAME_LENGTH = 20
export const MAX_FIRST_NAME_LENGTH = 12
export const INITIAL_DELAY_UNTIL_CLUSTER_TOOLTIP = 300
export const NUMBER_OF_CLUSTER_TOOL_TIP_VIEWS = 'NUMBER_OF_CLUSTER_TOOL_TIP_VIEWS'

export const SMALL_CLUSTER_SIZE_CUTOFF = 10
export const MEDIUM_CLUSTER_SIZE_CUTOFF = 100
export const LARGE_CLUSTER_SIZE_CUTOFF = 1000
export const MAX_NUMBER_OF_TOOLTIP_VIEWS = 4

export const LISTING_PHOTOS_CONTENT_PANEL = 'listingPhotosContentPanel'

// Display - Listings Map
export const STARTING_ZOOM = 13
export const MIN_MAP_ZOOM = 7
export const MAX_MAP_ZOOM = 16

export const MIN_CLUSTER_ZOOM = 7
export const MAX_CLUSTER_ZOOM = 12

export const CLUSTER_RADIUS = 150

export const CLUSTER_OPTIONS = {
  radius: CLUSTER_RADIUS,
  maxZoom: MAX_CLUSTER_ZOOM,
  minZoom: MIN_CLUSTER_ZOOM
}

export const MAX_NUMBER_OF_LISTINGS_SHOWN_ON_MAP = 200
export const LISTINGS_DELAY_UNTIL_SHOW_LOADER = 200
export const LISTINGS_MIN_LOADER_DISPLAY_TIME = 500
