import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Routes from '../routes'

import {
  generateColorRamp,
  addColorRampToThemeWithFunction,
  removeColorRampFromThemeWithFunction
} from '../helpers/pbhColors/customColorsHelpers'
import {
  selectCompanyThemePrimaryColor,
  selectCompanyThemeSecondaryColor,
  selectCompanyTheme
} from '../store/selectors/customerProfile'
import { selectToasts } from '../store/selectors/toasts'
import { setThemeCssColorVar } from '../hooks/theme'
import { useTheme, legacyThemes, LEGACY_THEME_NAMES } from '@homebotapp/hb-react-component-catalog'
import Notification from '../Components/shared/Notification'
import { LOADING_THEME } from '../constants/theme'
import DisclaimerModal from './DisclaimerModal'
import ErrorModal from './ErrorModal'
import { Toasts } from '../Components/shared/Toasts'
import { fetchCurrentUser } from '../actions/currentUser'
import { NativeAppQrCodeModal } from '../Components/shared/NativeAppQrCodeModal/NativeAppQrCodeModal'
import { MobileAppDrawer } from '../Components/MobileAppDrawer/MobileAppDrawer'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RecoilRoot } from 'recoil'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

interface HomebotProps {
  error: boolean
}

const queryClient = new QueryClient()

export const LIGHT_THEME_ENTERPRISE_COMPANIES = ['guild'] // Make sure these are lowercase

const Homebot = ({ error }: HomebotProps) => {
  const { theme, setTheme } = useTheme()

  const {} = usePlacesService({ apiKey: process.env.REACT_APP_GOOGLE_KEY }) // There was some crazy bug that was happening if the GoogleReactMap loaded before the Google Places API was loaded. This is a hack to make sure the Google Places API is loaded before the GoogleReactMap is loaded. This is a temporary fix until we can figure out a better way to handle this.

  // This referrer logic is specifically to prevent a flash of the 'hb_default' (dark) theme
  // when loading the LGW preview pages from our enterprise customers' websites
  // (which should always be 'light_neutral' themed).
  const referrer = document.referrer.toLowerCase()
  const referrerIsLightTheme = LIGHT_THEME_ENTERPRISE_COMPANIES.some(company => referrer.includes(company))

  const urlParams = new URLSearchParams(window.location.search)
  const optionalThemeFromQueryStringForLocalDev = urlParams.get('devTheme')
  const themeFromCustomerProfile = useSelector(selectCompanyTheme)
  const companyThemeFromApiResponse = referrerIsLightTheme
    ? 'light_neutral'
    : optionalThemeFromQueryStringForLocalDev || themeFromCustomerProfile || 'hb_default'
  const themePrimaryColor = useSelector(selectCompanyThemePrimaryColor)
  const themeSecondaryColor = useSelector(selectCompanyThemeSecondaryColor)
  const themeTilCompanyResponse = localStorage.getItem(LOADING_THEME) ?? 'hb_default'
  const toasts = useSelector(selectToasts)

  // Needed for the PURL preview
  // Initially load the previous loaded theme
  useEffect(() => {
    setTheme(legacyThemes[themeTilCompanyResponse])
    // Remove the hardcoded background that we use before react
    // loads and use the background color design token instead
    document.body.style.removeProperty('background-image')
  }, [setTheme, themeTilCompanyResponse])

  useEffect(() => {
    // Set company theme ONY if different from theme set in local storage - prevents re-renders
    // If the db column is nil, companyThemeFromApiResponse is null so we default to hb_default.
    // If it is undefined, we don't change anything because there will be a moment during refresh
    // where it is undefined and would cause a flash.
    //
    // Only touch this logic of you are the strongest of the strong.
    //
    if (companyThemeFromApiResponse !== undefined && companyThemeFromApiResponse !== theme) {
      const defaultIfResponseNull =
        companyThemeFromApiResponse === null ? LEGACY_THEME_NAMES.HB_DEFAULT : companyThemeFromApiResponse

      setTheme(legacyThemes[defaultIfResponseNull])

      document.body.style.removeProperty('background-image')
      localStorage.setItem('LOADING_THEME', defaultIfResponseNull)
    }
  }, [setTheme, companyThemeFromApiResponse, theme.name])

  useEffect(() => {
    if (themePrimaryColor) {
      setThemeCssColorVar('brand-primary-custom', themePrimaryColor) // no ramp for this color

      const colorRamp = generateColorRamp(themePrimaryColor)
      addColorRampToThemeWithFunction(setThemeCssColorVar, colorRamp, 'brand-primary-')
    }

    return () => {
      document?.documentElement?.style?.removeProperty?.(`--brand-primary-custom`) // no ramp for this color

      removeColorRampFromThemeWithFunction(document?.documentElement?.style?.removeProperty, '--brand-primary')
    }
  }, [themePrimaryColor])

  useEffect(() => {
    if (themeSecondaryColor) {
      setThemeCssColorVar('hb-colors-text-brand', themePrimaryColor) // no ramp for this color

      const colorRamp = generateColorRamp(themeSecondaryColor)
      addColorRampToThemeWithFunction(setThemeCssColorVar, colorRamp, 'hb-colors-brand-')
    }

    return () => {
      document.documentElement.style.removeProperty(`--hb-colors-text-brand`) // no ramp for this color

      removeColorRampFromThemeWithFunction(document.documentElement.style.removeProperty, '--hb-colors-brand-')
    }
  }, [themeSecondaryColor])

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Notification />
        <Routes />
        <DisclaimerModal />
        <MobileAppDrawer />
        <NativeAppQrCodeModal />
        <Toasts toasts={toasts} />
        {error && <ErrorModal />}
      </QueryClientProvider>
    </RecoilRoot>
  )
}

interface AppProps {
  error: boolean
}

export const App = (props: AppProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchCurrentUser())
  }, [dispatch])

  // Using design token theme provider
  return <Homebot error={props.error} />
}
