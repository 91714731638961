import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Stack,
  Text,
  useTheme
} from '@homebotapp/hb-react-component-catalog'
import { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import topographicalMapDark from '../../../assets/homes/img/topo-bg-wide.svg'
import topographicalMapLight from '../../../assets/homes/img/topo-bg-wide-light.svg'
import assumablesHomeIcon from '../../../assets/homes/img/assumable-home-icon.png'
import { ResponsiveValue } from '@chakra-ui/react'

import { AssumablesDirectMessage } from '../AssumablesDirectMessage/AssumablesDirectMessage'

const MSG = defineMessages({
  learnMore: {
    id: 'AssumablesLearnMoreModal.learnMore',
    defaultMessage: 'Learn more'
  },
  learnMoreAbout: {
    id: 'AssumablesLearnMoreModal.learnMoreAbout',
    defaultMessage: 'LEARN MORE ABOUT'
  },
  assumableMortgages: {
    id: 'AssumablesLearnMoreModal.assumableMortgages',
    defaultMessage: 'Assumable Mortgages'
  },
  assumableMortgageTagline: {
    id: 'AssumablesLearnMoreModal.assumableMortgageTagline',
    defaultMessage: 'Based on the data available to Homebot, the mortgage on this home may be assumable!'
  },
  whatIsAnAssumableMortgage: {
    id: 'AssumablesLearnMoreModal.whatIsAnAssumableMortgage',
    defaultMessage: 'What is an assumable mortgage?'
  },
  assumableMortgageExplanationSection1: {
    id: 'AssumablesLearnMoreModal.assumableMortgageExplanationSection1',
    defaultMessage: `An assumable mortgage allows you to take over the current owner’s existing mortgage if they consent to the assumption and other conditions are met.`
  },
  assumableMortgageExplanationSection2: {
    id: 'AssumablesLearnMoreModal.assumableMortgageExplanationSection2',
    defaultMessage: `You assume the responsibility of paying the debt, but the remaining balance, interest rate, and repayment method remain the same.`
  },
  assumableMortgageExplanationSection3: {
    id: 'AssumablesLearnMoreModal.assumableMortgageExplanationSection3',
    defaultMessage: `Assumable mortgages can be an attractive option to buyers when rates are high, because the assumed mortgage will likely have a lower rate.`
  },
  assumableMortgageExplanationSection4: {
    id: 'AssumablesLearnMoreModal.assumableMortgageExplanationSection4',
    defaultMessage: `Your monthly payment will include principal and interest toward both mortgages, as well as other charges like insurance, taxes, and HOA.`
  },
  breakdownBar: {
    id: 'AssumablesLearnMoreModal.breakdownBar',
    defaultMessage: 'Breakdown bar'
  },
  fullPriceOfHome: {
    id: 'AssumablesLearnMoreModal.fullPriceOfHome',
    defaultMessage: 'Full price of home'
  },
  whatDoYouNeedToConsider: {
    id: 'AssumablesLearnMoreModal.whatDoYouNeedToConsider',
    defaultMessage: 'What do you need to consider?'
  },

  whatDoYouNeedToConsiderSection1: {
    id: 'AssumablesLearnMoreModal.whatDoYouNeedToConsiderSection1',
    defaultMessage: `If you can cover the difference between the purchase price of the home and the amount remaining on the assumed loan (also known as the seller’s equity), you might not need a new mortgage.`
  },
  whatDoYouNeedToConsiderSection2: {
    id: 'AssumablesLearnMoreModal.whatDoYouNeedToConsiderSection2',
    defaultMessage: `Regardless, getting an expert’s advice is wise, as there are many details to consider. You’ll have to figure out whether you’ll need to finance the amount due at closing and if so, the best way to do it.`
  },
  canGovernmentProgramLoansBeAssumable: {
    id: 'AssumablesLearnMoreModal.canGovernmentProgramLoansBeAssumable',
    defaultMessage: 'Can government program loans be assumable?'
  },
  canGovernmentProgramLoansBeAssumableExplanationSection1: {
    id: 'AssumablesLearnMoreModal.canGovernmentProgramLoansBeAssumableExplanationSection1',
    defaultMessage: 'USDA, FHA, and VA loans may be assumable if certain conditions are met.'
  },
  canGovernmentProgramLoansBeAssumableExplanationSection2: {
    id: 'AssumablesLearnMoreModal.canGovernmentProgramLoansBeAssumableExplanationSection2',
    defaultMessage: `For VA loans, the buyer doesn’t need to be a service member but must qualify for the mortgage. For sellers with a VA loan, allowing an assumption may have effects on future VA loan entitlements.`
  },
  cancel: {
    id: 'AssumablesLearnMoreModal.cancel',
    defaultMessage: 'Cancel'
  },
  confirm: {
    id: 'AssumablesLearnMoreModal.confirm',
    defaultMessage: 'Confirm'
  },
  close: {
    id: 'AssumablesLearnMoreModal.close',
    defaultMessage: 'Close'
  },
  assumablesDm: {
    id: 'AssumablesLearnMoreModal.assumablesDm',
    defaultMessage: 'Ask {name} if you qualify'
  },
  success: {
    id: 'AssumablesLearnMoreModal.success',
    defaultMessage: 'Direct message sent!'
  },
  fail: {
    id: 'AssumablesLearnMoreModal.fail',
    defaultMessage: 'Direct message failed to send.'
  },
  assumedMortgage: {
    id: 'AssumablesLearnMoreModal.assumedMortgage',
    defaultMessage: 'Assumed mortgage'
  },
  downPayment: {
    id: 'AssumablesLearnMoreModal.downPayment',
    defaultMessage: 'Down payment'
  },
  lowerRate: {
    id: 'AssumablesLearnMoreModal.lowerRate',
    defaultMessage: 'Lower rate'
  },
  keepSellersRateAndTerm: {
    id: 'AssumablesLearnMoreModal.keepSellersRateAndTerm',
    defaultMessage: `Keep the seller\'s rate and term`
  },
  cashTowardRemainingBalance: {
    id: 'AssumablesLearnMoreModal.cashTowardRemainingBalance',
    defaultMessage: 'Cash toward the remaining balance'
  },
  borrowTheRestAtTheCurrentRate: {
    id: 'AssumablesLearnMoreModal.borrowTheRestAtTheCurrentRate',
    defaultMessage: 'Borrow the rest at the current rate'
  },
  secondMortgage: {
    id: 'AssumablesLearnMoreModal.secondMortgage',
    defaultMessage: 'Second mortgage'
  },
  higherRate: {
    id: 'AssumablesLearnMoreModal.higherRate',
    defaultMessage: 'Higher rate'
  }
})

interface AssumablesLearnMoreModalProps {
  buttonProps?: {
    size?: ResponsiveValue<string>
    leftIcon?: JSX.Element
    variant?: string
    colorScheme?: string
    width?: ResponsiveValue<string>
    bg?: ResponsiveValue<any>
    copy?: string
    tracking?: {
      guid: string
      ui_context: string
      descriptor: string
    }
  }
}

export const AssumablesLearnMoreModal = ({ buttonProps }: AssumablesLearnMoreModalProps) => {
  const intl = useIntl()
  const { theme } = useTheme()
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Button
        variant={buttonProps?.variant}
        colorScheme={buttonProps?.colorScheme}
        width={buttonProps?.width}
        size={buttonProps?.size as ResponsiveValue<string>}
        leftIcon={buttonProps?.leftIcon}
        tracking={buttonProps?.tracking}
        bg={buttonProps?.bg}
        onClick={() => setShowModal(true)}
      >
        {buttonProps?.copy ? buttonProps.copy : intl.formatMessage(MSG.learnMore)}
      </Button>
      <Modal isOpen={showModal} size={'full'} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent overflow='hidden' borderRadius={16} marginInline={[0, 5, '20%']} marginTop={[0, 6]} mb={[0, 8]}>
          <ModalHeader
            backgroundImage={`url(${theme.isLightThemeType ? topographicalMapLight : topographicalMapDark})`}
            backgroundSize='cover'
            backgroundBlendMode='lighten'
            _dark={{
              backgroundColor: 'blackAlpha.600'
            }}
          >
            <Flex>
              <Image src={assumablesHomeIcon} aria-hidden='true' h={20} marginLeft={-6} />
              <Box ml={4}>
                <Text size={'md'} mb={0}>
                  {intl.formatMessage(MSG.learnMoreAbout)}
                </Text>
                <Heading as={'h3'} size={'lg'} textAlign={'left'}>
                  {intl.formatMessage(MSG.assumableMortgages)}
                </Heading>
              </Box>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text size={['sm', 'sm', 'md']} mt={4} mb={[3, 7]}>
              {intl.formatMessage(MSG.assumableMortgageTagline)}
            </Text>
            <Flex flexWrap={'wrap'} mt={3}>
              <Box w={['100%', '100%', '50%']}>
                <Heading as={'h4'} size={'md'} mb={3}>
                  {intl.formatMessage(MSG.whatIsAnAssumableMortgage)}
                </Heading>
                <Text size={'xs'}>{intl.formatMessage(MSG.assumableMortgageExplanationSection1)}</Text>
                <Text size={'xs'} mt={3}>
                  {intl.formatMessage(MSG.assumableMortgageExplanationSection2)}
                </Text>
                <Text size={'xs'} mt={3}>
                  {intl.formatMessage(MSG.assumableMortgageExplanationSection3)}
                </Text>
                <Text size={'xs'} mt={3}>
                  {intl.formatMessage(MSG.assumableMortgageExplanationSection4)}
                </Text>
              </Box>
              <Card marginInline={'auto'} maxW={['100%', '100%', '45%']} p={3}>
                <Text size={'sm'} mb={0} textAlign={'center'}>
                  {intl.formatMessage(MSG.fullPriceOfHome)}
                </Text>
                <Box width={'100%'} />
                <Progress
                  mt={4}
                  min={0}
                  variant='multiSegment'
                  values={{
                    'success.700': 667,
                    'primary.600': 100,
                    'warning.700': 233
                  }}
                  max={1000}
                  w={'100%'}
                  aria-hidden='true'
                  aria-label={intl.formatMessage(MSG.breakdownBar)}
                />
                <Box>
                  <Flex mt={3} justifyContent={'space-between'}>
                    <Box>
                      <Flex alignItems='center' mb={3}>
                        {/* Colored bullet point */}
                        <Box bg='success.700' w={3} h={3} borderRadius='50%' mr={3}></Box>
                        <Box flex='1'>
                          <Text fontWeight='bold' size={'sm'} mb={0}>
                            {intl.formatMessage(MSG.assumedMortgage)}
                          </Text>
                          <Text size='xs'>{intl.formatMessage(MSG.keepSellersRateAndTerm)}</Text>
                        </Box>
                      </Flex>
                    </Box>
                    <Badge colorScheme='success' maxH={6} variant={'subtle'}>
                      <Text size={'2xs'} fontWeight={'bold'}>
                        {intl.formatMessage(MSG.lowerRate)}
                      </Text>
                    </Badge>
                  </Flex>

                  <Flex alignItems='center' mb={3}>
                    <Box bg='primary.600' w={3} h={3} borderRadius='50%' mr={3}></Box>
                    <Box flex='1'>
                      <Text fontWeight='bold' mb={0}>
                        {intl.formatMessage(MSG.downPayment)}
                      </Text>
                      <Text size='xs'>{intl.formatMessage(MSG.cashTowardRemainingBalance)}</Text>
                    </Box>
                  </Flex>

                  <Flex alignItems='center'>
                    <Box width={'100%'} justifyContent={'space-between'}>
                      {/* Colored bullet point */}
                      <Flex alignItems='center' mb={3}>
                        <Box bg='warning.700' w={3} h={3} borderRadius='50%' mr={3}></Box>
                        <Box flex='1' pr={2}>
                          <Text fontWeight='bold' mb={0}>
                            {intl.formatMessage(MSG.secondMortgage)}
                          </Text>
                          <Text size='xs'>{intl.formatMessage(MSG.borrowTheRestAtTheCurrentRate)}</Text>
                        </Box>
                      </Flex>
                    </Box>
                    <Badge colorScheme='warning' maxH={6} variant={'subtle'}>
                      <Text size={'2xs'} fontWeight={'bold'}>
                        Higher rate
                      </Text>
                    </Badge>
                  </Flex>
                </Box>
              </Card>
            </Flex>
            <hr />
            <Box>
              <Heading as={'h4'} size={'md'}>
                {intl.formatMessage(MSG.whatDoYouNeedToConsider)}
              </Heading>
              <Text size={'xs'} mt={3}>
                {intl.formatMessage(MSG.whatDoYouNeedToConsiderSection1)}
              </Text>
              <Text size={'xs'} mt={3}>
                {intl.formatMessage(MSG.whatDoYouNeedToConsiderSection2)}
              </Text>
            </Box>
            <hr />
            <Box>
              <Heading as={'h4'} size={'md'}>
                {intl.formatMessage(MSG.canGovernmentProgramLoansBeAssumable)}
              </Heading>
              <Text size={'xs'} mt={3}>
                {intl.formatMessage(MSG.canGovernmentProgramLoansBeAssumableExplanationSection1)}
              </Text>
              <Text size={'xs'} mt={3}>
                {intl.formatMessage(MSG.canGovernmentProgramLoansBeAssumableExplanationSection2)}
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Box textAlign={'center'} margin={'auto'} width={['100%', '50%']}>
              <Stack dir='column' spacing={3} align='center'>
                <AssumablesDirectMessage />

                <Button
                  variant='link'
                  colorScheme='primary'
                  onClick={() => setShowModal(false)}
                  width={['100%', '50%']}
                >
                  {intl.formatMessage(MSG.close)}
                </Button>
              </Stack>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
