/**
 * @file Local Storage Keys
 * */

export enum StorageKey {
  RecentlySelectedLocations = '@homebot:storage:RecentlySelectedLocations',
  CurrentSelectedLocation = '@homebot:storage:CurrentSelectedLocation',
  CurrentSearchLocation = '@homebot:storage:CurrentSearchLocation',
  HomeSearchMapState = '@homebot:storage:HomeSearchMapState'
}
