import { atom } from 'recoil'
import { StateKeys } from '../../../../constants/stateKeys'
import { StorageKey } from '../../../../constants/StorageKey'
import { storageEffect } from '../../../../effects/storageEffect'

export interface CurrentSearchLocation extends google.maps.places.AutocompletePrediction {
  latitude?: number
  longitude?: number
  lat?: number // looks like in some instance we return lat instead of latitude
  lon?: number // looks like in some instance we return lon instead of longitude
  name: string
  placeId: string
  stateAbbreviation: string
  city?: string | null
  zipCode?: string | null
  level?: string
  id?: string | null
}

export const currentSearchLocation = atom<CurrentSearchLocation | undefined>({
  key: StateKeys.currentSearchLocation,
  default: undefined,
  effects: [storageEffect(StorageKey.CurrentSearchLocation)]
})
