import { ScheduleTour, ScheduleTourPreferredAgentTypes } from '../../types/scheduleTour'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../types/rootState'
import { useSelector } from 'react-redux'
import { selectCustomerIsRealEstateAgent } from '../selectors/customerProfile'

export const WHERE = 'VIRTUAL_OR_IN_PERSON'
export const WHEN = 'WHEN'
export const CONTACT = 'CONTACT'
export const PREFERRED_AGENT = 'PREFERRED_AGENT'
export const QUESTIONS = 'QUESTIONS'
export const CONFIRMATION = 'CONFIRMATION'

export const STEPS = [WHERE, WHEN, CONTACT, PREFERRED_AGENT, QUESTIONS, CONFIRMATION]
export const STEPS_CONST = [WHERE, WHEN, CONTACT, PREFERRED_AGENT, QUESTIONS, CONFIRMATION] as const
export const STEPS_REA = [WHERE, WHEN, CONTACT, QUESTIONS, CONFIRMATION]

export type ScheduleTourStepType = (typeof STEPS)[number]

const initialState: ScheduleTour = {
  step: STEPS[0],
  showModal: false,
  inPersonTour: true,
  tourDate: new Date(),
  phone: '',
  preferredAgent: 'listingAgent',
  questionsText: ''
}

const scheduleTourSlice = createSlice({
  name: 'scheduleTour',
  initialState,
  reducers: {
    setScheduleTourStep: (state, action: PayloadAction<ScheduleTourStepType>) => {
      state.step = action.payload
    },
    setScheduleTourShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload
    },
    setScheduleTourIsInPerson: (state, action: PayloadAction<boolean>) => {
      state.inPersonTour = action.payload
    },
    setScheduleTourDate: (state, action: PayloadAction<Date>) => {
      state.tourDate = action.payload
    },
    setScheduleTourPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload
    },
    setScheduleTourPreferredAgent: (state, action: PayloadAction<ScheduleTourPreferredAgentTypes>) => {
      state.preferredAgent = action.payload
    },
    setScheduleTourQuestionsText: (state, action: PayloadAction<string>) => {
      state.questionsText = action.payload
    },
    setScheduleTourStepAdvanceOneStep: state => {
      state.step = STEPS[STEPS.indexOf(state.step) + 1]
    },
    setScheduleTourStepAdvanceTwoSteps: state => {
      state.step = STEPS[STEPS.indexOf(state.step) + 2]
    },
    setScheduleTourDataToInitialState: () => initialState
  }
})

export const selectScheduleTourStep = (state: RootState) => state.scheduleTour.step
export const selectScheduleTourShowModal = (state: RootState) => state.scheduleTour.showModal
export const selectScheduleTourIsInPerson = (state: RootState) => state.scheduleTour.inPersonTour
export const selectScheduleTourDate = (state: RootState) => state.scheduleTour.tourDate
export const selectScheduleTourPhone = (state: RootState) => state.scheduleTour.phone
export const selectScheduleTourQuestionsText = (state: RootState) => state.scheduleTour.questionsText
export const selectScheduleTourPreferredAgent = (state: RootState) => state.scheduleTour.preferredAgent

export const {
  setScheduleTourStep,
  setScheduleTourShowModal,
  setScheduleTourIsInPerson,
  setScheduleTourDate,
  setScheduleTourPhone,
  setScheduleTourQuestionsText,
  setScheduleTourStepAdvanceOneStep,
  setScheduleTourStepAdvanceTwoSteps,
  setScheduleTourDataToInitialState,
  setScheduleTourPreferredAgent
} = scheduleTourSlice.actions

export default scheduleTourSlice.reducer
